/* eslint-disable */
var isIE11 = !!window.MSInputMethodContext && !!window.document.documentMode;
function showBrowserAlert() {
	if (document.querySelector('.unsupported-browser')) {
		var d = document.getElementsByClassName('unsupported-browser');

		if (isIE11) {
			var child = document.getElementById('root');
			child.parentNode.removeChild(child);
			d[0].innerHTML =
				'<div style="padding: 20px"><h2>Unsupported browser!</h2><p><strong>You are using an unsupported browser version!</strong></p><p>We only support the recent versions of major browsers:</p><ul><li>Chrome 102 or higher</li><li>Safari 13.1 or higher</li><li>Firefox 103 or higher</li><li>Edge 103 or higher</li><li>Opera 89 or higher</li></ul></div>';
			d[0].style.display = 'block';
		}
	}
}
document.addEventListener('DOMContentLoaded', showBrowserAlert);
/* eslint-enable */
